export const environment = {
  production: true,
  appVersion: '2.49.10-21c02e3e',
  checkVersion: true,
  builtAppHash: '21c02e3e156eff3b5a557935f4e341295ff44f04',
  centrifugoWebsocketUrl: 'wss://centri.zumti.com/connection/websocket',
  jitsiurl: 'meetx.turkpages.com',
  webSiteUrl: 'https://web.robotel.ai/',
  apiUrl: 'https://be.robotel.ai/',
  natsUrl: 'wss://centri.zumti.com',
  googleCientId: '572001134774-bveghvbh9jda001oge723ujj45jbrihq.apps.googleusercontent.com',
  livekitUrl:   'wss://live.robotel.ai',
  customerName: 'robotelai',
  s3AccountUrl: 'https://robotelai.s3.eu-central-1.amazonaws.com/v2/',
};